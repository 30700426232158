"use strict";
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import { Fragment, jsx, jsxs } from "react/jsx-runtime";
import { useCallback, useContext, useState } from "react";
import { Box } from "@mui/material";
import { TextfieldComponent } from "@v2/components/forms/textfield.component";
import { DeviceAPI, DeviceEndpoints } from "@v2/feature/device/device.api";
import { DevicePossessionType, DeviceTransitStatus } from "@v2/feature/device/device.interface";
import { getDeviceOwnerByDevicePossession, getModelImage } from "@v2/feature/device/device.util";
import { UserAvatar } from "@v2/feature/user/components/user-avatar.component";
import { useCachedUsers } from "@v2/feature/user/context/cached-users.context";
import { drawerContentSx } from "@v2/feature/user/features/user-profile/details/components/styles.layout";
import { useApiClient } from "@v2/infrastructure/api-client/api-client.hook";
import { usePolyglot } from "@v2/infrastructure/i18n/i8n.util";
import { themeColors } from "@v2/styles/colors.styles";
import { LocalDate } from "@v2/util/local-date";
import { Form, FormikProvider, useFormik } from "formik";
import * as yup from "yup";
import { GlobalContext } from "@/GlobalState";
import useMessage from "@/hooks/notification.hook";
import { nestErrorMessage } from "@/lib/errors";
import { canAccessScopes } from "@/lib/scopes";
import { DrawerModal } from "@/v2/components/theme-components/drawer-modal.component";
import { LoaderButton } from "@/v2/components/theme-components/loading-button.component";
import { Typography } from "@/v2/components/typography/typography.component";
import { buttonBoxDrawerSx } from "@/v2/styles/settings.styles";
import { spacing } from "@/v2/styles/spacing.styles";
export const DeviceTransitActionItem = ({
  deviceTransitTodo,
  afterClose,
  refresh
}) => {
  const { data: deviceTransit } = useApiClient(DeviceEndpoints.getTransitsByTransitId(deviceTransitTodo.id), {
    suspense: false
  });
  const [isOpen, setIsOpen] = useState(Boolean(deviceTransitTodo));
  return /* @__PURE__ */ jsx(
    DeviceTransitStartModal,
    {
      isOpen,
      setIsOpen,
      afterClose,
      onClose: () => setIsOpen(false),
      refresh,
      devicePossessionReceiver: deviceTransitTodo == null ? void 0 : deviceTransitTodo.receiverPossession,
      devicePossessionSender: deviceTransitTodo == null ? void 0 : deviceTransitTodo.senderPossession,
      deviceTransitDto: deviceTransit != null ? deviceTransit : void 0
    }
  );
};
export const DeviceTransitDrawer = ({
  deviceTransitId,
  afterClose,
  refresh,
  isOpen,
  setIsOpen
}) => {
  const { data: deviceTransit } = useApiClient(DeviceEndpoints.getTransitsByTransitId(deviceTransitId != null ? deviceTransitId : 0), {
    suspense: false
  });
  return /* @__PURE__ */ jsx(
    DeviceTransitStartModal,
    {
      isOpen,
      setIsOpen,
      afterClose,
      onClose: () => setIsOpen(false),
      refresh,
      devicePossessionReceiver: deviceTransit == null ? void 0 : deviceTransit.receiver,
      devicePossessionSender: deviceTransit == null ? void 0 : deviceTransit.sender,
      deviceTransitDto: deviceTransit != null ? deviceTransit : void 0
    }
  );
};
const DeviceTransitStartModal = ({
  isOpen,
  setIsOpen,
  onClose,
  afterClose,
  refresh,
  devicePossessionReceiver,
  devicePossessionSender,
  deviceTransitDto
}) => {
  var _a, _b;
  const [globalState] = useContext(GlobalContext);
  const currentUser = globalState.user;
  const { polyglot } = usePolyglot();
  const isAdmin = canAccessScopes(currentUser, ["devices:all"]);
  const isSender = currentUser.userId === (devicePossessionSender == null ? void 0 : devicePossessionSender.possessionId);
  const isReceiver = currentUser.userId === (devicePossessionReceiver == null ? void 0 : devicePossessionReceiver.possessionId);
  const isSenderZelt = [DevicePossessionType.ZeltStock, DevicePossessionType.ZeltStorage].includes(
    (_a = devicePossessionSender == null ? void 0 : devicePossessionSender.possessionType) != null ? _a : DevicePossessionType.User
  );
  const isReceiverZelt = [DevicePossessionType.ZeltStock, DevicePossessionType.ZeltStorage].includes(
    (_b = devicePossessionReceiver == null ? void 0 : devicePossessionReceiver.possessionType) != null ? _b : DevicePossessionType.User
  );
  const { getCachedUserById } = useCachedUsers();
  return /* @__PURE__ */ jsx(DrawerModal, { isOpen, setIsOpen, afterClose, children: deviceTransitDto ? (isSender || isAdmin) && deviceTransitDto.status === DeviceTransitStatus.Pending && !isSenderZelt ? /* @__PURE__ */ jsx(
    DeviceTransitInShippingConfirmContent,
    {
      onClose: () => {
        onClose();
        afterClose();
      },
      deviceTransitDto,
      devicePossessionSender,
      devicePossessionReceiver,
      getCachedUserById,
      refresh
    }
  ) : (isReceiver || isAdmin) && deviceTransitDto.status === DeviceTransitStatus.Shipping && !isReceiverZelt ? /* @__PURE__ */ jsx(
    DeviceTransitShippedConfirmation,
    {
      onClose: () => {
        onClose();
        afterClose();
        refresh();
      },
      devicePossessionSender,
      devicePossessionReceiver,
      deviceTransitDto,
      getCachedUserById
    }
  ) : isSender && deviceTransitDto.status === DeviceTransitStatus.Shipping ? /* @__PURE__ */ jsx(
    DeviceTransitViewConfirmContent,
    {
      devicePossessionReceiver,
      devicePossessionSender,
      deviceTransitDto,
      getCachedUserById
    }
  ) : isReceiver && deviceTransitDto.status === DeviceTransitStatus.Pending ? /* @__PURE__ */ jsx(
    DeviceTransitViewConfirmContent,
    {
      devicePossessionReceiver,
      devicePossessionSender,
      deviceTransitDto,
      getCachedUserById
    }
  ) : isSenderZelt || isReceiverZelt ? /* @__PURE__ */ jsx(
    DeviceTransitViewConfirmContent,
    {
      devicePossessionReceiver,
      devicePossessionSender,
      deviceTransitDto,
      getCachedUserById
    }
  ) : /* @__PURE__ */ jsx(Typography, { variant: "caption", color: "Grey", children: polyglot.t("DeviceTransitActionItem.noActionAvailable") }) : /* @__PURE__ */ jsx(Typography, { variant: "caption", color: "Grey", children: polyglot.t("DeviceTransitActionItem.noDeviceSelected") }) });
};
const DeviceTransitInShippingConfirmContent = ({
  deviceTransitDto,
  devicePossessionSender,
  devicePossessionReceiver,
  onClose,
  getCachedUserById,
  refresh
}) => {
  var _a, _b, _c, _d, _e, _f, _g, _h;
  const [loading, setLoading] = useState(false);
  const [showTrackingLinkForm, setShowTrackingLinkForm] = useState(false);
  const [showMessage] = useMessage();
  const { polyglot } = usePolyglot();
  const getUserName = useCallback(
    (assignedUserId) => {
      var _a2;
      const user = getCachedUserById(assignedUserId);
      return polyglot.t((_a2 = user == null ? void 0 : user.displayName) != null ? _a2 : "");
    },
    [getCachedUserById, polyglot]
  );
  const formik = useFormik({
    initialValues: {
      id: deviceTransitDto == null ? void 0 : deviceTransitDto.id,
      companyId: deviceTransitDto == null ? void 0 : deviceTransitDto.companyId,
      deviceId: deviceTransitDto == null ? void 0 : deviceTransitDto.deviceId,
      senderId: deviceTransitDto == null ? void 0 : deviceTransitDto.senderId,
      receiverId: deviceTransitDto == null ? void 0 : deviceTransitDto.receiverId,
      status: deviceTransitDto == null ? void 0 : deviceTransitDto.status,
      notes: deviceTransitDto == null ? void 0 : deviceTransitDto.notes,
      senderAddress: deviceTransitDto == null ? void 0 : deviceTransitDto.senderAddress,
      deliveryAddress: deviceTransitDto == null ? void 0 : deviceTransitDto.deliveryAddress,
      createdBy: deviceTransitDto == null ? void 0 : deviceTransitDto.createdBy,
      trackingLink: deviceTransitDto == null ? void 0 : deviceTransitDto.trackingLink,
      isShippingManagedByZelt: deviceTransitDto == null ? void 0 : deviceTransitDto.isShippingManagedByZelt,
      sender: deviceTransitDto == null ? void 0 : deviceTransitDto.sender,
      receiver: deviceTransitDto == null ? void 0 : deviceTransitDto.receiver,
      device: deviceTransitDto == null ? void 0 : deviceTransitDto.device,
      createdAt: deviceTransitDto == null ? void 0 : deviceTransitDto.createdAt,
      updatedAt: deviceTransitDto == null ? void 0 : deviceTransitDto.updatedAt
    },
    validationSchema: yup.object({}),
    onSubmit: () => __async(void 0, null, function* () {
      try {
        setLoading(true);
        setShowTrackingLinkForm(true);
      } catch (error) {
        showMessage(
          `${polyglot.t("DeviceTransitInShippingConfirmContent.errorMessage", {
            errorMessage: nestErrorMessage(error)
          })}`,
          "error"
        );
      } finally {
        setLoading(false);
      }
    })
  });
  if (showTrackingLinkForm) {
    return /* @__PURE__ */ jsx(DeviceTransitInShippingAddTrackingLink, { onClose, refresh, deviceTransitDto });
  }
  return /* @__PURE__ */ jsx(FormikProvider, { value: formik, children: /* @__PURE__ */ jsxs(Form, { onSubmit: formik.handleSubmit, style: drawerContentSx, children: [
    /* @__PURE__ */ jsxs(Box, { sx: { display: "flex", flexDirection: "column", gap: spacing.g8 }, children: [
      /* @__PURE__ */ jsx(Typography, { variant: "title2", children: polyglot.t("DeviceTransitInShippingConfirmContent.title") }),
      /* @__PURE__ */ jsx(Typography, { variant: "caption", children: polyglot.t("DeviceTransitInShippingConfirmContent.description", {
        userName: getUserName(deviceTransitDto.createdBy)
      }) }),
      /* @__PURE__ */ jsx(Box, { sx: { display: "flex", justifyContent: "center", mb: 4, mt: 4 }, children: getModelImage((_a = deviceTransitDto.device) == null ? void 0 : _a.type, (_b = deviceTransitDto.device) == null ? void 0 : _b.modelName, {
        width: "200px",
        height: "auto"
      }) })
    ] }),
    ((_c = deviceTransitDto.device) == null ? void 0 : _c.deviceName) && /* @__PURE__ */ jsx(ViewItem, { label: "Device name", value: (_d = deviceTransitDto.device) == null ? void 0 : _d.deviceName }),
    ((_e = deviceTransitDto.device) == null ? void 0 : _e.modelName) && /* @__PURE__ */ jsx(ViewItem, { label: "Model name", value: (_f = deviceTransitDto.device) == null ? void 0 : _f.modelName }),
    ((_g = deviceTransitDto.device) == null ? void 0 : _g.serialNumber) && /* @__PURE__ */ jsx(ViewItem, { label: "Serial number", value: (_h = deviceTransitDto.device) == null ? void 0 : _h.serialNumber }),
    /* @__PURE__ */ jsx(Typography, { variant: "title4", sx: { mt: spacing.mt10 }, children: polyglot.t("DeviceTransitInShippingConfirmContent.sendFrom") }),
    devicePossessionSender ? /* @__PURE__ */ jsx(Fragment, { children: /* @__PURE__ */ jsx(
      ViewItem,
      {
        label: "Sender",
        value: /* @__PURE__ */ jsxs(Box, { sx: { display: "flex", alignItems: "center", gap: "8px" }, children: [
          devicePossessionSender.possessionType === DevicePossessionType.User && /* @__PURE__ */ jsx(
            UserAvatar,
            {
              userId: devicePossessionSender.possessionId,
              size: "xxsmall"
            },
            `avatar-${devicePossessionSender.possessionId}`
          ),
          /* @__PURE__ */ jsx(Typography, { variant: "title4", children: getDeviceOwnerByDevicePossession(devicePossessionSender, null, getCachedUserById) })
        ] })
      }
    ) }) : /* @__PURE__ */ jsx(ViewItem, { label: "Sender", value: "Unknown" }),
    /* @__PURE__ */ jsx(Typography, { variant: "title4", sx: { mt: spacing.mt10 }, children: polyglot.t("DeviceTransitInShippingConfirmContent.shipTo") }),
    devicePossessionReceiver && /* @__PURE__ */ jsx(Fragment, { children: /* @__PURE__ */ jsx(
      ViewItem,
      {
        label: "Receiver",
        value: /* @__PURE__ */ jsxs(Box, { sx: { display: "flex", alignItems: "center", gap: "8px" }, children: [
          devicePossessionReceiver.possessionType === DevicePossessionType.User && /* @__PURE__ */ jsx(
            UserAvatar,
            {
              userId: devicePossessionReceiver.possessionId,
              size: "xxsmall"
            },
            `avatar-${devicePossessionReceiver.possessionId}`
          ),
          /* @__PURE__ */ jsx(Typography, { variant: "title4", children: getDeviceOwnerByDevicePossession(devicePossessionReceiver, null, getCachedUserById) })
        ] })
      }
    ) }),
    (deviceTransitDto == null ? void 0 : deviceTransitDto.deliveryAddress) && /* @__PURE__ */ jsx(ViewItem, { label: "Address", value: deviceTransitDto.deliveryAddress }),
    /* @__PURE__ */ jsx(Box, { sx: buttonBoxDrawerSx, children: /* @__PURE__ */ jsx(
      LoaderButton,
      {
        sizeVariant: "medium",
        colorVariant: "primary",
        name: "Mark as sent",
        fullWidth: true,
        type: "submit",
        loading
      }
    ) })
  ] }) });
};
const DeviceTransitViewConfirmContent = ({
  deviceTransitDto,
  devicePossessionReceiver,
  devicePossessionSender,
  getCachedUserById
}) => {
  var _a, _b, _c, _d, _e, _f, _g, _h;
  const { polyglot } = usePolyglot();
  return /* @__PURE__ */ jsxs(Box, { style: drawerContentSx, children: [
    /* @__PURE__ */ jsx(Typography, { variant: "title2", children: polyglot.t("DeviceTransitInShippingConfirmContent.title2") }),
    /* @__PURE__ */ jsx(Box, { sx: { display: "flex", justifyContent: "center", mb: 4, mt: 4 }, children: getModelImage((_a = deviceTransitDto.device) == null ? void 0 : _a.type, (_b = deviceTransitDto.device) == null ? void 0 : _b.modelName, {
      width: "200px",
      height: "auto"
    }) }),
    /* @__PURE__ */ jsx(
      ViewItem,
      {
        label: "Tracking link",
        value: deviceTransitDto.trackingLink ? /* @__PURE__ */ jsx(
          "a",
          {
            href: deviceTransitDto.trackingLink,
            target: "_blank",
            rel: "noreferrer",
            style: { color: themeColors.DarkGrey },
            children: "Click here"
          }
        ) : "N/A"
      }
    ),
    ((_c = deviceTransitDto.device) == null ? void 0 : _c.deviceName) && /* @__PURE__ */ jsx(ViewItem, { label: "Device name", value: (_d = deviceTransitDto.device) == null ? void 0 : _d.deviceName }),
    ((_e = deviceTransitDto.device) == null ? void 0 : _e.modelName) && /* @__PURE__ */ jsx(ViewItem, { label: "Model name", value: (_f = deviceTransitDto.device) == null ? void 0 : _f.modelName }),
    ((_g = deviceTransitDto.device) == null ? void 0 : _g.serialNumber) && /* @__PURE__ */ jsx(ViewItem, { label: "Serial number", value: (_h = deviceTransitDto.device) == null ? void 0 : _h.serialNumber }),
    /* @__PURE__ */ jsx(Typography, { variant: "title4", sx: { mt: spacing.mt10 }, children: polyglot.t("DeviceTransitInShippingConfirmContent.sendFrom") }),
    devicePossessionSender ? /* @__PURE__ */ jsx(Fragment, { children: /* @__PURE__ */ jsx(
      ViewItem,
      {
        label: "Sender",
        value: /* @__PURE__ */ jsxs(Box, { sx: { display: "flex", alignItems: "center", gap: "8px" }, children: [
          devicePossessionSender.possessionType === DevicePossessionType.User && /* @__PURE__ */ jsx(
            UserAvatar,
            {
              userId: devicePossessionSender.possessionId,
              size: "xxsmall"
            },
            `avatar-${devicePossessionSender.possessionId}`
          ),
          /* @__PURE__ */ jsx(Typography, { variant: "title4", children: getDeviceOwnerByDevicePossession(devicePossessionSender, null, getCachedUserById) })
        ] })
      }
    ) }) : /* @__PURE__ */ jsx(ViewItem, { label: "Sender", value: "Unknown" }),
    /* @__PURE__ */ jsx(Typography, { variant: "title4", sx: { mt: spacing.mt10 }, children: polyglot.t("DeviceTransitInShippingConfirmContent.shipTo") }),
    devicePossessionReceiver && /* @__PURE__ */ jsx(
      ViewItem,
      {
        label: "Receiver",
        value: /* @__PURE__ */ jsxs(Box, { sx: { display: "flex", alignItems: "center", gap: "8px" }, children: [
          devicePossessionReceiver.possessionType === DevicePossessionType.User && /* @__PURE__ */ jsx(
            UserAvatar,
            {
              userId: devicePossessionReceiver.possessionId,
              size: "xxsmall"
            },
            `avatar-${devicePossessionReceiver.possessionId}`
          ),
          /* @__PURE__ */ jsx(Typography, { variant: "title4", children: getDeviceOwnerByDevicePossession(devicePossessionReceiver, null, getCachedUserById) })
        ] })
      }
    ),
    (deviceTransitDto == null ? void 0 : deviceTransitDto.deliveryAddress) && /* @__PURE__ */ jsx(ViewItem, { label: "Address", value: deviceTransitDto.deliveryAddress })
  ] });
};
const isUrlValid = (url) => {
  const pattern = new RegExp(
    "^(https?:\\/\\/)?((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|((\\d{1,3}\\.){3}\\d{1,3}))(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*(\\?[;&a-z/\\d%_.~+=-]*)?(\\#[-a-z\\d_]*)?$",
    // fragment locator
    "i"
  );
  return pattern.test(url);
};
const DeviceTransitInShippingAddTrackingLink = ({
  deviceTransitDto,
  onClose,
  refresh
}) => {
  var _a;
  const [loading, setLoading] = useState(false);
  const [showMessage] = useMessage();
  const { polyglot } = usePolyglot();
  const formik = useFormik({
    initialValues: {
      trackingLink: (_a = deviceTransitDto == null ? void 0 : deviceTransitDto.trackingLink) != null ? _a : ""
    },
    validationSchema: yup.object({}),
    onSubmit: (values) => __async(void 0, null, function* () {
      try {
        setLoading(true);
        const validatedLink = values.trackingLink.startsWith("https://") ? values.trackingLink : `https://${values.trackingLink}`;
        if (!isUrlValid(validatedLink)) throw new Error("Tracking link is not valid URL!");
        yield DeviceAPI.updateDeviceTransitTrackingLink(deviceTransitDto.id, validatedLink);
        yield DeviceAPI.changeDeviceOwnerSetTransitInShipping(deviceTransitDto.id);
        showMessage("Device transit updated successfully", "success");
        yield refresh();
        onClose();
      } catch (error) {
        showMessage(`Something went wrong. ${nestErrorMessage(error)}`, "error");
      } finally {
        setLoading(false);
      }
    })
  });
  return /* @__PURE__ */ jsx(FormikProvider, { value: formik, children: /* @__PURE__ */ jsxs(Form, { onSubmit: formik.handleSubmit, style: drawerContentSx, children: [
    /* @__PURE__ */ jsxs(Box, { sx: { display: "flex", flexDirection: "column", gap: spacing.g8 }, children: [
      /* @__PURE__ */ jsxs(Typography, { variant: "title2", children: [
        " ",
        polyglot.t("DeviceTransitInShippingAddTrackingLink.title")
      ] }),
      /* @__PURE__ */ jsx(Typography, { variant: "caption", children: polyglot.t("DeviceTransitInShippingAddTrackingLink.description") })
    ] }),
    /* @__PURE__ */ jsx(
      TextfieldComponent,
      {
        label: "Tracking Link",
        name: "trackingLink",
        value: formik.values.trackingLink,
        onChange: formik.handleChange,
        placeholder: "https://",
        helperText: "Enter the tracking link, starting with https://"
      }
    ),
    /* @__PURE__ */ jsxs(Box, { sx: buttonBoxDrawerSx, children: [
      /* @__PURE__ */ jsx(
        LoaderButton,
        {
          sizeVariant: "medium",
          colorVariant: "secondary",
          name: "Skip",
          fullWidth: true,
          onClick: () => __async(void 0, null, function* () {
            yield DeviceAPI.changeDeviceOwnerSetTransitInShipping(deviceTransitDto.id);
            showMessage("Device transit updated successfully", "success");
            yield refresh();
            onClose();
          }),
          loading
        }
      ),
      /* @__PURE__ */ jsx(
        LoaderButton,
        {
          sizeVariant: "medium",
          colorVariant: "primary",
          name: "Save",
          fullWidth: true,
          type: "submit",
          loading
        }
      )
    ] })
  ] }) });
};
const DeviceTransitShippedConfirmation = ({
  deviceTransitDto,
  devicePossessionSender,
  devicePossessionReceiver,
  onClose,
  getCachedUserById
}) => {
  var _a, _b, _c, _d, _e, _f;
  const [loading, setLoading] = useState(false);
  const [showMessage] = useMessage();
  const { polyglot } = usePolyglot();
  const isStorageOrStock = (deviceTransitDto == null ? void 0 : deviceTransitDto.receiver) && [DevicePossessionType.ZeltStorage, DevicePossessionType.ZeltStock].includes(
    deviceTransitDto == null ? void 0 : deviceTransitDto.receiver.possessionType
  );
  const formik = useFormik({
    initialValues: {
      id: deviceTransitDto == null ? void 0 : deviceTransitDto.id,
      companyId: deviceTransitDto == null ? void 0 : deviceTransitDto.companyId,
      deviceId: deviceTransitDto == null ? void 0 : deviceTransitDto.deviceId,
      senderId: deviceTransitDto == null ? void 0 : deviceTransitDto.senderId,
      receiverId: deviceTransitDto == null ? void 0 : deviceTransitDto.receiverId,
      status: deviceTransitDto == null ? void 0 : deviceTransitDto.status,
      notes: deviceTransitDto == null ? void 0 : deviceTransitDto.notes,
      senderAddress: deviceTransitDto == null ? void 0 : deviceTransitDto.senderAddress,
      deliveryAddress: deviceTransitDto == null ? void 0 : deviceTransitDto.deliveryAddress,
      createdBy: deviceTransitDto == null ? void 0 : deviceTransitDto.createdBy,
      trackingLink: deviceTransitDto == null ? void 0 : deviceTransitDto.trackingLink,
      isShippingManagedByZelt: deviceTransitDto == null ? void 0 : deviceTransitDto.isShippingManagedByZelt,
      sender: deviceTransitDto == null ? void 0 : deviceTransitDto.sender,
      receiver: deviceTransitDto == null ? void 0 : deviceTransitDto.receiver,
      device: deviceTransitDto == null ? void 0 : deviceTransitDto.device,
      createdAt: deviceTransitDto == null ? void 0 : deviceTransitDto.createdAt,
      updatedAt: deviceTransitDto == null ? void 0 : deviceTransitDto.updatedAt
    },
    validationSchema: yup.object({}),
    onSubmit: () => __async(void 0, null, function* () {
      try {
        setLoading(true);
        yield DeviceAPI.changeDeviceOwnerSetTransitAsDelivered(deviceTransitDto.id, new LocalDate().toDateString());
        showMessage("Device transit updated successfully", "success");
        onClose();
      } catch (error) {
        showMessage(`Something went wrong. ${nestErrorMessage(error)}`, "error");
      } finally {
        setLoading(false);
      }
    })
  });
  return /* @__PURE__ */ jsx(FormikProvider, { value: formik, children: /* @__PURE__ */ jsxs(Form, { onSubmit: formik.handleSubmit, style: drawerContentSx, children: [
    /* @__PURE__ */ jsx(Typography, { variant: "title2", children: !isStorageOrStock ? polyglot.t("DeviceTransitShippedConfirmation.title") : polyglot.t("DeviceTransitShippedConfirmation.titleStorage") }),
    /* @__PURE__ */ jsx(Box, { sx: { display: "flex", justifyContent: "center", mb: 4, mt: 4 }, children: getModelImage((_a = deviceTransitDto.device) == null ? void 0 : _a.type, (_b = deviceTransitDto.device) == null ? void 0 : _b.modelName, {
      width: "200px",
      height: "auto"
    }) }),
    /* @__PURE__ */ jsx(
      ViewItem,
      {
        label: "Tracking link",
        value: deviceTransitDto.trackingLink ? /* @__PURE__ */ jsx(
          "a",
          {
            href: deviceTransitDto.trackingLink,
            target: "_blank",
            rel: "noreferrer",
            style: { color: themeColors.DarkGrey },
            children: "Click here"
          }
        ) : "N/A"
      }
    ),
    ((_c = deviceTransitDto.device) == null ? void 0 : _c.modelName) && /* @__PURE__ */ jsx(ViewItem, { label: "Model name", value: (_d = deviceTransitDto.device) == null ? void 0 : _d.modelName }),
    ((_e = deviceTransitDto.device) == null ? void 0 : _e.serialNumber) && /* @__PURE__ */ jsx(ViewItem, { label: "Serial number", value: (_f = deviceTransitDto.device) == null ? void 0 : _f.serialNumber }),
    /* @__PURE__ */ jsx(Typography, { variant: "title4", sx: { mt: spacing.mt10 }, children: polyglot.t("DeviceTransitInShippingConfirmContent.sendFrom") }),
    devicePossessionSender ? /* @__PURE__ */ jsx(Fragment, { children: /* @__PURE__ */ jsx(
      ViewItem,
      {
        label: "Sender",
        value: /* @__PURE__ */ jsxs(Box, { sx: { display: "flex", alignItems: "center", gap: "8px" }, children: [
          devicePossessionSender.possessionType === DevicePossessionType.User && /* @__PURE__ */ jsx(
            UserAvatar,
            {
              userId: devicePossessionSender.possessionId,
              size: "xxsmall"
            },
            `avatar-${devicePossessionSender.possessionId}`
          ),
          /* @__PURE__ */ jsx(Typography, { variant: "title4", children: getDeviceOwnerByDevicePossession(devicePossessionSender, null, getCachedUserById) })
        ] })
      }
    ) }) : /* @__PURE__ */ jsx(ViewItem, { label: "Sender", value: "Unknown" }),
    /* @__PURE__ */ jsxs(Typography, { variant: "title4", children: [
      " ",
      polyglot.t("DeviceTransitShippedConfirmation.shipTo")
    ] }),
    deviceTransitDto && devicePossessionReceiver && /* @__PURE__ */ jsx(
      ViewItem,
      {
        label: "Receiver",
        value: /* @__PURE__ */ jsxs(Box, { sx: { display: "flex", alignItems: "center", gap: "8px" }, children: [
          devicePossessionReceiver.possessionType === DevicePossessionType.User && /* @__PURE__ */ jsx(
            UserAvatar,
            {
              userId: devicePossessionReceiver.possessionId,
              size: "xxsmall"
            },
            `avatar-${devicePossessionReceiver.possessionId}`
          ),
          /* @__PURE__ */ jsx(Typography, { variant: "title4", children: getDeviceOwnerByDevicePossession(devicePossessionReceiver, null, getCachedUserById) })
        ] })
      }
    ),
    (deviceTransitDto == null ? void 0 : deviceTransitDto.deliveryAddress) && /* @__PURE__ */ jsx(ViewItem, { label: "Address", value: deviceTransitDto.deliveryAddress }),
    !isStorageOrStock && /* @__PURE__ */ jsx(Box, { sx: buttonBoxDrawerSx, children: /* @__PURE__ */ jsx(
      LoaderButton,
      {
        sizeVariant: "medium",
        colorVariant: "primary",
        name: "Confirm",
        fullWidth: true,
        type: "submit",
        loading
      }
    ) })
  ] }) });
};
const ViewItem = ({ label, value }) => {
  return /* @__PURE__ */ jsxs(Box, { sx: { display: "flex", alignItems: "center", gap: spacing.g4 }, children: [
    /* @__PURE__ */ jsxs(Typography, { variant: "caption", children: [
      label,
      ":"
    ] }),
    /* @__PURE__ */ jsx(Typography, { variant: "title4", children: value })
  ] });
};
